import { MACCION } from '@/utils/consts'
import _ from '@/utils/lodash'

export default {
  async selectMaccionExistente(Vue, idmaterialSistema, idordenTrabajo) {
    if (idmaterialSistema){
      const ordenTrabajoMatsist = await Vue.$offline.ordenTrabajoMatsist.selectPorIdmaterialSistema(
        idordenTrabajo, idmaterialSistema
      )
      if (ordenTrabajoMatsist.length > 0) {
        const idordenTrabajoMatsist = ordenTrabajoMatsist[0].orden_trabajo_matsist.idorden_trabajo_matsist
        const tables = Vue.$offline.db.tables
        return Vue.$offline.db
          .select()
          .from(tables.accion_ejec)
          .innerJoin(
            tables.maccion,
            tables.accion_ejec.idmaccion.eq(tables.maccion.idmaccion)
          )
          .where(
            Vue.$offline.db.op.and(
              tables.accion_ejec.idorden_trabajo_matsist.eq(idordenTrabajoMatsist),
              tables.accion_ejec.estado.gt(0),
            )
          )
          .exec()
      }
    }
    return []
  },
  async selectMaccion (Vue, idtsubfamilia, accionesExistentes) {
    let where = []
    let whereExlude = []
    const tables = Vue.$offline.db.tables
    if (accionesExistentes.length > 0) {
      whereExlude.push(
        Vue.$offline.db.op.not(
          tables.maccion.idmaccion.in(accionesExistentes)
        )
      )
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.maccion_tsubfam.idtsubfamilia.eq(idtsubfamilia),
        tables.maccion.estado.gt(0),
        tables.maccion.clasificacion.in([
          MACCION.clasificacion.revision_anual,
          MACCION.clasificacion.revision_semestral,
          MACCION.clasificacion.revision_trimestral,
          MACCION.clasificacion.recarga,
          MACCION.clasificacion.retimbrado,
          MACCION.clasificacion.presurizacion,
          MACCION.clasificacion.otros,
        ]),
        ...whereExlude
      )
    )
    return Vue.$offline.db
      .select()
      .from(tables.maccion_tsubfam)
      .innerJoin(
        tables.maccion,
        tables.maccion_tsubfam.idmaccion.eq(tables.maccion.idmaccion)
      )
      .where(...where)
      .exec()
  },
  async selectCodigosSubsis(Vue, idsistema) {
    const tables = Vue.$offline.db.tables
    const subsis = await Vue.$offline.db
      .select()
      .from(tables.subsis)
      .where(
        Vue.$offline.db.op.and(
          tables.subsis.estado.gt(0),
          tables.subsis.idsistema.eq(idsistema),
        )
      )
      .exec()
    return _.map(subsis, 'codigo')
  },
}
