<template>
  <b10-base>
    <v-alert
      v-if="form.idsubsis_idtsubsis && form.idsubsis_idtsubsis.tipo === 'tsubsis'"
      color="warning"
      dark
      small
    >
      Se creará un nuevo subsistema
    </v-alert>
    <b10-autocomplete
      v-model="form.idsubsis_idtsubsis"
      :disabled="!!idmaterialSistema || !!idsubsis"
      :items="subsisTsubsis"
      :rules="formRules.idsubsis_idtsubsis"
      item-value="value"
      item-text="descripcion"
      label="Subsistema"
      clearable
      @change="subsisTsubsisSelected"
    >
      <template
        #item="{ item, attrs, on }"
      >
        <v-list-item
          v-bind="attrs"
          :disabled="item.value.tipo === 'divider'"
          v-on="on"
        >
          <v-list-item-content
            v-if="item.value.tipo === 'divider'"
            :class="item.value.color"
            class="white--text pa-1 rounded"
          >
            {{ item.descripcion }}
          </v-list-item-content>
          <v-list-item-content
            v-else
          >
            {{ item.descripcion }}
          </v-list-item-content>
        </v-list-item>
      </template>
    </b10-autocomplete>
    <v-text-field
      v-if="form.idsubsis_idtsubsis && form.idsubsis_idtsubsis.tipo === 'tsubsis'"
      v-model="form.subsis_codigo"
      clearable
      flat
      label="Código del subsistema"
      :rules="formRules.subsis_codigo"
    />
    <v-text-field
      v-if="form.idsubsis_idtsubsis && form.idsubsis_idtsubsis.tipo === 'tsubsis'"
      v-model="form.subsis_descripcion"
      clearable
      flat
      label="Descripción del subsistema"
      :rules="formRules.subsis_descripcion"
    />
    <div>
      <v-chip
        v-for="(field, key) in maccionesExistentes"
        :key="key"
        class="mt-1 ml-1"
      >
        {{ field.maccion.descripcion }}
      </v-chip>
    </div>

    <b10-autocomplete
      v-model="form.acciones"
      :items="macciones"
      item-text="maccion.descripcion"
      item-value="maccion.idmaccion"
      label="Acciones"
      multiple
      clearable
      chips
      deletable-chips
    />
    <v-text-field
      v-if="!idmaterialSistema"
      v-model.number="form.unidades"
      label="Unidades"
      clearable
      type="number"
      :rules="formRules.unidades"
    />
    <v-checkbox
      v-if="!idmaterialSistema && form.unidades > 1"
      v-model="form.desglosar"
      label="Desglosar unidades"
    />
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import formMixin from '@/mixins/formMixin'
import Data from './LparteTrabajoMaterialAfectadoFormData'

export default {
  mixins: [formMixin],
  props: {
    idparteTrabajo: {
      type: Number,
      required: true,
    },
    idtsubfamilia: {
      type: Number,
      required: true,
    },
    idsubsis: {
      type: [Number, String],
      default: '',
    },
    idmaterialSistema: {
      type: [Number, String],
      default: '',
    },
  },
  data () {
    return {
      form: {
        idsubsis_idtsubsis: null,
        subsis_codigo: null,
        subsis_descripcion: null,
        acciones: [],
        unidades: 1,
        desglosar: true,
      },
      formRules: {
        idsubsis_idtsubsis: [
          v => !!v || 'Campo requerido',
        ],
        subsis_codigo: [
          v => !!v || 'Campo requerido',
          v => {
            return !this.inCodigosExistentes.includes(v) || 'Código de subsistema duplicado'
          }
        ],
        subsis_descripcion: [
          v => !!v || 'Campo requerido',
        ],
        unidades: [
          v => !!v || 'Campo requerido',
        ],
      },
      macciones: [],
      subsisTsubsis: [],
      inCodigosExistentes: [],
      maccionesExistentes: [],
    }
  },
  async created () {
    const parteTrabajo = await this.$offline.parteTrabajo.row(this.idparteTrabajo)
    const ordenTrabajo = await this.$offline.ordenTrabajo.row(parteTrabajo.parte_trabajo.idorden_trabajo)
    this.maccionesExistentes = await Data.selectMaccionExistente(
      this,
      this.idmaterialSistema,
      ordenTrabajo.orden_trabajo.idorden_trabajo
    )
    this.macciones = await Data.selectMaccion(
      this,
      this.idtsubfamilia,
      _.map(this.maccionesExistentes, 'maccion.idmaccion')
    )
    this.inCodigosExistentes = await Data.selectCodigosSubsis(this, ordenTrabajo.orden_trabajo.idsistema)
    this.subsisTsubsis = await this.$offline.ordenTrabajoSubsis.selectSubsisTsubsis(
      parteTrabajo.parte_trabajo.idorden_trabajo
    )
    if (this.idmaterialSistema) {
      this.$set(this.form, 'idsubsis_idtsubsis', this.findSubsisIdsubsis(this.idsubsis))
    } else {
      // seleccionar como subsistema por defecto
      // 1) si se pasó propiedad idsubsis
      if (this.idsubsis) {
        this.$set(this.form, 'idsubsis_idtsubsis', this.findSubsisIdsubsis(this.idsubsis))
      } else {
        // 2) si el sistema tiene un subsistema que coincide con tsubfamilia.idtsubsis del artículo
        const tsubfamilia = await this.$offline.tsubfamilia.row(this.idtsubfamilia)
        const subsis = this.findSubsisIdtsubsis(tsubfamilia.tsubfamilia.idtsubsis)
        if (subsis) {
          this.$set(this.form, 'idsubsis_idtsubsis', subsis)
        } else {
          // 3) tipo de subsistema que coincide con tsubfamilia.idtsubsis del artículo
          const tsubsisComportamientoSubfam = this.findTsubsisIdtsubsis(tsubfamilia.tsubfamilia.idtsubsis)
          if (tsubsisComportamientoSubfam) {
            this.$set(this.form, 'idsubsis_idtsubsis', tsubsisComportamientoSubfam)
          }
        }
      }
    }
    // forzar evento
    this.subsisTsubsisSelected()
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
    findSubsisIdsubsis (idsubsis) {
      const indexSubsis = _.findIndex(
        this.subsisTsubsis, {
          value: {
            // idsubsis string -> se puede insertar offline
            id: idsubsis.toString(),
          }
        }
      )
      if (indexSubsis > -1) {
        return this.subsisTsubsis[indexSubsis].value
      }
    },
    findSubsisIdtsubsis (idtsubsis) {
      const indexSubsis = _.findIndex(
        this.subsisTsubsis, {
          value: {
            tipo: 'subsis',
            idtsubsis,
          }
        }
      )
      if (indexSubsis > -1) {
        return this.subsisTsubsis[indexSubsis].value
      }
    },
    findTsubsisIdtsubsis (idtsubsis) {
      const indexTsubsis = _.findIndex(
        this.subsisTsubsis, {
          value: {
            tipo: 'tsubsis',
            id: idtsubsis,
          }
        }
      )
      if (indexTsubsis > -1) {
        return this.subsisTsubsis[indexTsubsis].value
      }
    },
    subsisTsubsisSelected () {
      if (
        this.form.idsubsis_idtsubsis &&
        this.form.idsubsis_idtsubsis.codigoPorDefecto &&
        this.form.idsubsis_idtsubsis.descripcionPorDefecto
      ) {
        this.$set(this.form, 'subsis_codigo', this.form.idsubsis_idtsubsis.codigoPorDefecto)
        this.$set(this.form, 'subsis_descripcion', this.form.idsubsis_idtsubsis.descripcionPorDefecto)
      } else {
        this.$set(this.form, 'subsis_codigo', null)
        this.$set(this.form, 'subsis_descripcion', null)
      }
    },
  },
}
</script>
